import React, {useContext} from 'react';
import {Autocomplete, Button, IconButton, styled, TextField} from "@mui/material";
import AppContext from "../AppContext";
import {useFormik} from "formik";
import {
  ErrorMessages,
  startRequestConfigInitialValues,
  ViewType
} from "../const";
import {parse, format, addDays, isAfter, isValid} from "date-fns";
import {formatStartRequest} from "../utils/reportUtils";
import {useSnackbar} from "notistack";
import * as yup from "yup";
import {StartRequest} from "../types";
import InputMask from "react-input-mask";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {removeAtIndex} from "../utils/arrayUtils";

const hhmmddMMyyRegExp = new RegExp(/^(?:[01]\d|2[0-3]):[0-5]\d (?:0[1-9]|[12]\d|3[01])\.(?:0[1-9]|1[0-2])\.\d{2}$/);

const startRequestValidationSchema = yup.object().shape({
  startDate: yup.string().required(ErrorMessages.Required).matches(hhmmddMMyyRegExp, { message: ErrorMessages.HHmmddMMyy}),
  endDate: yup.string().required(ErrorMessages.Required).matches(hhmmddMMyyRegExp, { message: ErrorMessages.HHmmddMMyy}),
  vehicleTypes: yup.array().of(yup.object({
    vehicleType: yup.string().required(ErrorMessages.Required),
    serialNumber: yup.string().required(ErrorMessages.Required),
  })),
  altitude: yup.string().required(ErrorMessages.Required),
  streamName: yup.string().required(ErrorMessages.Required),
  contactPhone: yup.string().required(ErrorMessages.Required),
});

type StartRequestFormValuesType = Omit<StartRequest, 'startDate' | 'endDate'> & {
  startDate: string;
  endDate: string;
};

const calcStartDateInitialValue = (configDate?: Date) => {
  if(configDate && isValid(configDate)) {
    return isAfter(configDate, new Date()) ? format(configDate, 'HH:mm dd.MM.yy') : `${format(configDate, 'HH:mm')} ${format(new Date(), 'dd.MM.yy')}`;
  }
  return `__:__, ${format(new Date(), 'dd.MM.yy')}`;
};
const calcEndDateInitialValue = (configDate?: Date) => {
  if(configDate && isValid(configDate)){
    return isAfter(configDate, addDays(new Date(), 1)) ? format(configDate, 'HH:mm dd.MM.yy') : `${format(configDate, 'HH:mm')} ${format(addDays(new Date(), 1), 'dd.MM.yy')}`;
  }
  return `__:__, ${format(addDays(new Date(), 1), 'dd.MM.yy')}`;
};

const StartRequestForm = () => {
  const { config, startRequestConfig, updateStartRequestConfig, updateView, globalConfig } = useContext(AppContext);
  const {enqueueSnackbar} = useSnackbar();

  const extendedAmmoOptions = [...globalConfig.ammo, ...Object.keys(globalConfig.objectives).filter(item => globalConfig.objectives[item].countAsAmmoInRequest)];

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik<StartRequestFormValuesType>({
    initialValues: {
      ...(startRequestConfig || startRequestConfigInitialValues),
      startDate: calcStartDateInitialValue(startRequestConfig?.startDate),
      endDate: calcEndDateInitialValue(startRequestConfig?.endDate),
    },
    validationSchema: startRequestValidationSchema,
    onSubmit: values => {
      let startDate, endDate;
      try {
        startDate = parse(values.startDate, 'HH:mm dd.MM.yy', new Date());
        endDate = parse(values.endDate, 'HH:mm dd.MM.yy', new Date());
      }catch(e){
      }

      const newStartRequestConfig = {
        ...values,
        startDate,
        endDate,
      }


      updateStartRequestConfig(newStartRequestConfig);
      const formatedStartRequest = formatStartRequest(config, newStartRequestConfig);
      navigator.clipboard.writeText(formatedStartRequest);
      enqueueSnackbar('Заявка скопійована до буферу обміну', { variant: "success" });
      updateView(ViewType.Reports);
    },
    enableReinitialize: true,
  });

  const addVehicle = () => {
    setFieldValue('vehicleTypes', [...values.vehicleTypes, {vehicleType: '', serialNumber: ''}]);
  }

  const removeVehicle = (index: number) => {
    setFieldValue('vehicleTypes', removeAtIndex(values.vehicleTypes, index));
  }

  const handleChangeAmmo = (value: string[]) => {
    if(value.length > values.ammo.length){
      const config = globalConfig.ammoComposition[value[value.length - 1]];
      if(config && !values.initiators.includes(config.initiator)){
        setFieldValue('initiators', [...values.initiators, config.initiator]);
      }
      if(config && !values.detonators.includes(config.detonator)){
        setFieldValue('detonators', [...values.detonators, config.detonator]);
      }
    }
    setFieldValue('ammo', value || []);
  };

  return <StyledForm onSubmit={handleSubmit}>
    <InputMask
      mask="99:99 99.99.99"
      value={values.startDate}
      onChange={handleChange}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete="off"
        label="Час початку"
        name="startDate"
        error={touched.startDate && Boolean(errors.startDate)}
        helperText={touched.startDate && errors.startDate}
      />}
    </InputMask>
    <InputMask
      mask="99:99 99.99.99"
      value={values.endDate}
      onChange={handleChange}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete="off"
        label="Час закінчення"
        name="endDate"
        error={touched.endDate && Boolean(errors.endDate)}
        helperText={touched.endDate && errors.endDate}
      />}
    </InputMask>
    {values.vehicleTypes.map((vehicle, i) => <VehiclesWrapper key={i}>
      <VehicleFieldWrapper>
        <VehicleAutocomplete
          disableClearable
          value={vehicle.vehicleType}
          onChange={(e, value) => setFieldValue(`vehicleTypes[${i}].vehicleType`, value)}
          options={globalConfig.vehicleTypes}
          renderInput={(params) => <TextField
            {...params}
            name={`vehicleTypes[${i}].vehicleType`}
            label={`Тип засобу`}
            // @ts-ignore
            error={touched.vehicleTypes && Boolean(errors.vehicleTypes?.[i]?.vehicleType)}
            // @ts-ignore
            helperText={touched.vehicleTypes && errors.vehicleTypes?.[i]?.vehicleType}
          />}
        />
        {i !== 0 ? <IconButton
          size="large"
          color="inherit"
          onClick={() => removeVehicle(i)}
        >
          <RemoveIcon />
        </IconButton> : <BtnPlaceholder />}
        {i === values.vehicleTypes.length - 1 ? <IconButton
          size="large"
          color="inherit"
          onClick={addVehicle}
        >
          <AddIcon />
        </IconButton> : <BtnPlaceholder />}
      </VehicleFieldWrapper>
      <StyledTextField
        name={`vehicleTypes[${i}].serialNumber`}
        value={vehicle.serialNumber}
        onChange={handleChange}
        label={"Серійний номер"}
        // @ts-ignore
        error={touched.vehicleTypes && Boolean(errors.vehicleTypes?.[i]?.serialNumber)}
        // @ts-ignore
        helperText={touched.vehicleTypes && errors.vehicleTypes?.[i]?.serialNumber}
      />
    </VehiclesWrapper>)}
    <StyledTextField
      label="Висота"
      variant="outlined"
      name="altitude"
      value={values.altitude}
      onChange={handleChange}
      error={touched.altitude && Boolean(errors.altitude)}
      helperText={touched.altitude && errors.altitude}
    />
    <Autocomplete
      multiple
      value={values.ammo}
      onChange={(e, value) => handleChangeAmmo(value)}
      options={extendedAmmoOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="ammo"
        label="БК"
        error={touched.ammo && Boolean(errors.ammo)}
        helperText={touched.ammo && errors.ammo}
      />}
    />
    <Autocomplete
      multiple
      value={values.initiators}
      onChange={(e, value) => setFieldValue('initiators', value || [])}
      options={globalConfig.initiators}
      renderInput={(params) => <StyledTextField
        {...params}
        name="initiators"
        label="Ініціатор"
        error={touched.initiators && Boolean(errors.initiators)}
        helperText={touched.initiators && errors.initiators}
      />}
    />
    <Autocomplete
      multiple
      value={values.detonators}
      onChange={(e, value) => setFieldValue('detonators', value || [])}
      options={globalConfig.detonators}
      renderInput={(params) => <StyledTextField
        {...params}
        name="detonators"
        label="Детонатор"
        error={touched.detonators && Boolean(errors.detonators)}
        helperText={touched.detonators && errors.detonators}
      />}
    />
    <StyledTextField
      label="Контактний номер"
      variant="outlined"
      name="contactPhone"
      value={values.contactPhone}
      onChange={handleChange}
      error={touched.contactPhone && Boolean(errors.contactPhone)}
      helperText={touched.contactPhone && errors.contactPhone}
    />
    <StyledTextField
      label="Стрім"
      variant="outlined"
      name="streamName"
      value={values.streamName}
      onChange={handleChange}
      error={touched.streamName && Boolean(errors.streamName)}
      helperText={touched.streamName && errors.streamName}
    />
    <Button variant="contained" type="submit">Скопіювати заявку</Button>
  </StyledForm>
};

export default StartRequestForm;

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
const VehiclesWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
const VehicleFieldWrapper = styled('div')`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
const VehicleAutocomplete = styled(Autocomplete)`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing(4)};
`;
const BtnPlaceholder = styled('div')`
  width: 48px;
  height: 48px;
  min-width: 48px;
`;