import {StartRequest} from "./types";

export enum ErrorMessages {
  Required =  'Обов\'язкове поле',
  HHmm = 'Має бути у форматі гг:хх',
  HHmmddMMyyyy = 'Має бути у форматі гг:хх, дд.мм.рррр',
  HHmmddMMyy = 'Має бути у форматі гг:хх дд.мм.рр',
  JSONOnly = 'Файл має бути у форматі .json',
  NotValidFile = 'Не валідний файл'
};

export const configKey = 'config';
export const reportsKey = 'reports_v4';
export const targetMapKey = 'targets';
export const startRequestConfigKey = 'startRequestConfig';
export const globalConfigKey = 'globalConfig';

export const mgrsCoordinatesRegExp = /^\d{2}[A-Z] [A-Z]{2} \d{5} \d{5}$/;
export const mgrsCoordinatesStartRegExp = /^\d{2}[A-Z] [A-Z]{2}$/;
export const hhmmRexExp = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)

export enum ViewType {
  startRequest = 'Start request',
  Reports = 'Reports',
  Config = 'Config',
  Checklist = 'Checklist',
  GlobalConfig = 'GlobalConfig',
}

export const defaultInitialTargetValue = '36T XT';
export const initialAmmoValue = {
  ammoType: '',
  ammoQuantity: '',
  detonator: '',
  detonatorQuantity: '',
  initiator: '',
  initiatorQuantity: '',
}

export const startRequestConfigInitialValues: StartRequest = {
  startDate: undefined,
  endDate: undefined,
  vehicleTypes: [{
    vehicleType: '',
    serialNumber: '',
  }],
  altitude: '',
  streamName: '',
  contactPhone: '',
  initiators: [],
  detonators: [],
  ammo: [],
}