import React, {useContext, useState} from 'react';
import Header from "../components/Header";
import {Button, IconButton, styled, Typography} from "@mui/material";
import {ErrorMessages, ViewType} from "../const";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AppContext from "../AppContext";
import Wrapper from "../components/Wrapper";
import {useSnackbar} from "notistack";
import {isGlobalConfigValid} from "../utils/isGlobalConfigValid";

const ConfigUpload = () => {
  const {config, globalConfig, updateView, updateGlobalConfig} = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if(e){
      const file = e.target?.files?.[0];
      if(file && file.type === 'application/json'){
        const reader = new FileReader();

        reader.onload = (e) => {
          setIsLoading(true);
          const result = e.target?.result;

          if (typeof result === 'string') {
            try{
              const parsedData = JSON.parse(result);
              if(!isGlobalConfigValid(parsedData)){
                enqueueSnackbar(ErrorMessages.NotValidFile, { variant: "error" });
                setIsLoading(false);
                return;
              }
              updateGlobalConfig(parsedData);
              setIsLoading(false);
              enqueueSnackbar('Налаштування успішно імортовано', { variant: "success" });

              updateView(ViewType.Config);
            }catch(e){
              console.log(e);
              enqueueSnackbar(ErrorMessages.NotValidFile, { variant: "error" });
              setIsLoading(false);
            }
          }else{
            enqueueSnackbar(ErrorMessages.NotValidFile, { variant: "error" });
            setIsLoading(false);
          }
        };

        reader.readAsText(file);
      }else{
        enqueueSnackbar(ErrorMessages.JSONOnly, { variant: "error" });
      }
    }
  }

  return <>
    <Header>
      {Boolean(globalConfig) && <IconButton
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 2 }}
          onClick={() => updateView(config?.crewComposition.length ? ViewType.Reports : ViewType.Config)}
      >
          <ChevronLeftIcon />
      </IconButton>}
      <Typography sx={{ flexGrow: 1}} variant="h5">Загальні налаштування</Typography>
    </Header>
    <StyledWrapper>
      <WarningText>Увага!!! Після оновлення конфігурації усі раніше внесені дані буде очищено для запобігання конфліктам</WarningText>
      <input
        accept=".json"
        style={{ display: 'none' }}
        id="button-upload"
        type="file"
        onChange={handleUpload}
      />
      <label htmlFor="button-upload">
        <Button disabled={isLoading} variant="contained" component="span">Завантажити файл конфігурації</Button>
      </label>
    </StyledWrapper>
  </>
};

export default ConfigUpload;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`;

const WarningText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
`;