import {GlobalConfig} from "../types";

//TODO create more complex validation
export const isGlobalConfigValid = (globalConfig: GlobalConfig) => {
  return globalConfig?.defaultFlightTime > 0
    && Object.keys(globalConfig?.crewComposition || {}).length > 0
    && globalConfig?.vehicleTypes.length > 0
    && Object.keys(globalConfig?.ammo || {}).length > 0
    && Object.keys(globalConfig?.detonators || {}).length > 0
    && Object.keys(globalConfig?.initiators || {}).length > 0
    && Object.keys(globalConfig?.objectives || {}).length > 0
    && globalConfig.results.length > 0
}