import React, {useContext} from 'react';

import {Button, styled, TextField} from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputMask from 'react-input-mask';

import {configInitialValues, CrewCompositionItem} from "./const";
import {ErrorMessages, mgrsCoordinatesStartRegExp} from "../../const";
import AppContext from "../../AppContext";

const filter = createFilterOptions<CrewCompositionItem>();

const configFormSchema = yup.object().shape({
  crew: yup.string().required(ErrorMessages.Required),
  crewComposition: yup.array().of(yup.string()).min(1, 'У екіпажі має бути хоча б 1 людина'),
  takeOffLocation:  yup.string().required(ErrorMessages.Required),
  attackArea:  yup.string().required(ErrorMessages.Required),
  frequencies:  yup.string().required(ErrorMessages.Required),
  altitude:  yup.string().required(ErrorMessages.Required),
  areaOfResponsibility:  yup.string().required(ErrorMessages.Required),
  initialTargetValue: yup.string().required(ErrorMessages.Required).matches(mgrsCoordinatesStartRegExp, { message: 'Має бути у форматі MGRS' }),
  stream: yup.string(),
});

interface ConfigFormProps {
  onSubmit: () => void;
}

const ConfigForm: React.FC<ConfigFormProps> = ({ onSubmit }) => {

  const { config, updateConfig, globalConfig } = useContext(AppContext);

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: config || configInitialValues,
    validationSchema: configFormSchema,
    onSubmit: values => {
      updateConfig(values);
      onSubmit();
    },
    enableReinitialize: true,
  });

  return <StyledForm onSubmit={handleSubmit}>
      <Autocomplete
        disableClearable
        value={values.crew}
        onChange={(e, value) => {
          setFieldValue('crew', value);
          setFieldValue('crewComposition', []);
        }}
        options={Object.keys(globalConfig.crewComposition)}
        renderInput={(params) => <StyledTextField
          {...params}
          name="crew"
          label="Екіпаж"
          error={touched.crew && Boolean(errors.crew)}
          helperText={touched.crew && errors.crew}
        />}
      />
      <Autocomplete
        multiple
        value={values.crewComposition}
        onChange={(event, newValue) => {
          if(!newValue) {
            setFieldValue('crewComposition', [])
          }else {
            setFieldValue('crewComposition', newValue.map(item => typeof item === 'object' ? item.value : item))
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              value: inputValue,
              label: `Додати "${inputValue}"`,
            });
          }

          return filtered;
        }}
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={(globalConfig.crewComposition?.[values.crew] || []) as CrewCompositionItem[]}
        getOptionLabel={(option) => {
          if (typeof option === 'object') {
            return option.label;
          }

          return option;
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {typeof option === 'object' ? option.label : option}
            </li>
          );
        }}
        renderInput={(params) => <StyledTextField
          {...params}
          name="crewComposition"
          label="Склад екіпажу"
          error={touched.crewComposition && Boolean(errors.crewComposition)}
          helperText={touched.crewComposition && errors.crewComposition}
        />}
      />
      <StyledTextField
        label="Взліт"
        variant="outlined"
        name="takeOffLocation"
        value={values.takeOffLocation}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.takeOffLocation && Boolean(errors.takeOffLocation)}
        helperText={touched.takeOffLocation && errors.takeOffLocation}
      />
      <StyledTextField
        label="Район ураження"
        variant="outlined"
        name="attackArea"
        value={values.attackArea}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.attackArea && Boolean(errors.attackArea)}
        helperText={touched.attackArea && errors.attackArea}
      />
      <StyledTextField
        label="Частоти"
        variant="outlined"
        name="frequencies"
        value={values.frequencies}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.frequencies && Boolean(errors.frequencies)}
        helperText={touched.frequencies && errors.frequencies}
      />
      <StyledTextField
        label="Висота"
        variant="outlined"
        name="altitude"
        value={values.altitude}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.altitude && Boolean(errors.altitude)}
        helperText={touched.altitude && errors.altitude}
      />
      <StyledTextField
        label="Зона відповідальності"
        variant="outlined"
        name="areaOfResponsibility"
        value={values.areaOfResponsibility}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.areaOfResponsibility && Boolean(errors.areaOfResponsibility)}
        helperText={touched.areaOfResponsibility && errors.areaOfResponsibility}
      />
      <InputMask
          mask="99a aa"
          value={values.initialTargetValue}
          onChange={(e) => setFieldValue('initialTargetValue', e.target.value.toUpperCase())}
      >
          {/*@ts-ignore*/}
          {(inputProps) => <StyledTextField
              {...inputProps}
              autoComplete='off'
              name="initialTargetValue"
              label="Початок координат"
              error={touched.initialTargetValue && Boolean(errors.initialTargetValue)}
              helperText={touched.initialTargetValue && errors.initialTargetValue}
          />}
      </InputMask>
      {globalConfig?.shouldHaveStreamField && <StyledTextField
          label="Стрім"
          variant="outlined"
          name="stream"
          value={values.stream}
          onChange={handleChange}
          error={touched.stream && Boolean(errors.stream)}
          helperText={touched.stream && errors.stream}
        />}
      <Button variant="contained" type="submit">Зберегти</Button>
    </StyledForm>;
};

export default ConfigForm;

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;